<template>
  <div
    v-shortkey="['esc']"
    class="modals-get-started"
    :class="{'modals-get-started_blur': showStraightOffModalSignUp}"
    @contextmenu.self.prevent
    @click="closeModal"
    @shortkey="closeModal"
  >
    <div
      v-if="ifLoader"
      class="if-loader"
    ></div>
    <div class="modals-get-started-bg">
      <div
        class="modals-get-started__content"
        @click.stop
        @contextmenu.prevent
      >
        <div
          v-if="!showStraightOffModalSignUp"
          class="modals-get-started__close"
          @click="closeModal"
        >
          <i class="icon-close-modal"></i>
        </div>
        <div class="modals-get-started__title">
          Let’s get it started!
        </div>
        <div
          v-if="!showStraightOffModalSignUp"
          class="modals-get-started__desc"
        >
          Our limited-time offer ends soon. Signup and go live, or lock your spot and launch later.
        </div>
        <form
          method="post"
          action=""
          class="modals-get-started-form"
        >
          <div class="modals-get-started-form__input">
            <i class="icon-user-modal"></i>
            <input
              v-model="pricingObj.firstName"
              type="text"
              placeholder="First name"
              autofocus
              name="firstName"
              :class="{'error': validator.errors.has('firstName')}"
              @keyup="formatField($event)"
            >
            <transition name="fade-msg">
              `
              <span
                v-show="validator.errors.has('firstName')"
                class="help-modal is-danger"
              >{{ validator.errors.first('firstName') }}</span>
            </transition>
          </div>
          <div class="modals-get-started-form__input">
            <i class="icon-user-modal"></i>
            <input
              v-model="pricingObj.lastName"
              type="text"
              placeholder="Last name"
              name="lastName"
              :class="{'error': validator.errors.has('lastName')}"
              @keyup="formatField($event)"
            >
            <transition name="fade-msg">
              <span
                v-show="validator.errors.has('lastName')"
                class="help-modal is-danger"
              >{{ validator.errors.first('lastName') }}</span>
            </transition>
          </div>
          <div class="modals-get-started-form__input">
            <i class="icon-msg-modal"></i>
            <input
              v-model="pricingObj.email"
              type="email"
              placeholder="Email"
              name="email"
              :class="{'error': validator.errors.has('email')}"
            >
            <transition name="fade-msg">
              <span
                v-show="validator.errors.has('email')"
                class="help-modal is-danger"
              >{{ validator.errors.first('email') }}</span>
            </transition>
          </div>
          <div class="modals-get-started-form__input">
            <i class="icon-org-modal"></i>
            <input
              v-model="pricingObj.organization"
              type="text"
              placeholder="Organization"
              name="organization"
              :class="{'error': validator.errors.has('organization')}"
              @keyup="formatField($event)"
            >
            <transition name="fade-msg">
              <span
                v-show="validator.errors.has('organization')"
                class="help-modal is-danger"
              >{{ validator.errors.first('organization') }}</span>
            </transition>
          </div>
          <div class="modals-get-started-form-radio">
            <radio
              :radioValue="'Church'"
              checked
              :radioText="'Church'"
              :radioName="'freeOrPaidEvent'"
              :radioCheked="pricingObj.type === 'church'"
              @changeRadio="pricingObj.type = 'church'"
            />
            <radio
              :radioValue="'Ministry'"
              :radioText="'Ministry'"
              :radioName="'freeOrPaidEvent'"
              :radioCheked="pricingObj.type === 'ministry'"
              @changeRadio="pricingObj.type = 'ministry'"
            />
          </div>
          <div
            v-if="false"
            class="recaptcha-google-block"
          >
            <recaptcha
              ref="recaptcha"
              @verify="submitCaptcha"
            />
          </div>
          <input
            type="submit"
            class="modals-get-started-form__btn"
            value="Continue"
            name="submit"
            @click.prevent="submitData()"
          >
          <!--        :disabled="!verifyCaptchaPropDef"-->
          <!--        :class="{'modals-get-started-form__btn_disabled': !verifyCaptchaPropDef}"-->
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import radio from '@/components/radio';
import pricingApi from '@/api/pricingApi';
import VueRecaptcha from 'vue-recaptcha';
import recaptcha from '@/components/recaptcha';
import { Validator } from 'vee-validate';

export default {
  name: 'ModalsGetStarted',
  components: {
    radio,
    VueRecaptcha,
    recaptcha,
  },
  props: ['showModalAttendance', 'showStraightOffModalSignUp'],
  data() {
    return {
      ifLoader: false,
      validator: {},
      verifyCaptchaPropDef: false,
    };
  },
  computed: {
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
    userId: {
      get() {
        return this.$store.getters.userId;
      },
      set(data) {
        this.$store.commit('setUserId', data);
      },
    },
    verifyCaptchaProp: {
      get() {
        return this.verifyCaptchaPropDef;
      },
      set(data) {
        this.verifyCaptchaPropDef = data;
      },
    },
    pricingObj: {
      get() {
        return this.$store.getters.computedPricingObj;
      },
      set(data) {
        this.$store.commit('setPricingObj', data);
      },
    },
  },
  beforeMount() {
    this.validator = Validator.create();
    // this.$refs.captcha.reset()
    const dict = {
      en: {
        custom: {
          firstName: {
            required: 'The First Name is required.',
          },
          lastName: {
            required: 'The Last Name is required.',
          },
          email: {
            required: 'The Email is required.',
            email: 'The Email field must be a valid email.',
          },
          organization: {
            required: 'The Organization is required.',
          },
        },
      },
    };
    this.validator.localize(dict);
    this.validator.attach({ name: 'firstName', rules: { required: true } });
    this.validator.attach({ name: 'lastName', rules: { required: true } });
    this.validator.attach({ name: 'email', rules: { required: true, email: true } });
    this.validator.attach({ name: 'organization', rules: { required: true } });
  },
  methods: {
    closeModal() {
      if (!this.showStraightOffModalSignUp) {
        this.showModalGetStarted = false;
      }
    },
    formatField(ev) {
      const el = ev.target;
      const val = el.value.replace(/\s{2,}/g, ' ').split(' ');
      const res = [];
      for (const idx in val) {
        if (val[idx] !== ' ') {
          res.push(val[idx].substr(0, 1).toUpperCase() + val[idx].substr(1).toLowerCase());
        }
      }
      el.value = res.join(' ');
    },
    submitCaptcha(response) {
      this.validator.validateAll({
        firstName: this.pricingObj.firstName,
        lastName: this.pricingObj.lastName,
        email: this.pricingObj.email,
        organization: this.pricingObj.organization,
      }).then((result) => {
        if (result) {
          let type = 0;
          if (this.pricingObj.type === 'church') {
            type = 1;
          } else {
            type = 2;
          }
          const allData = {
            firstname: this.pricingObj.firstName,
            lastname: this.pricingObj.lastName,
            email: this.pricingObj.email,
            organization: this.pricingObj.organization,
            organizationType: type,
            // tags: ['Not a Client']
          };
          this.ifLoader = true;
          window.fbq('trackCustom', 'Sign Up Form Step 1');
          pricingApi.createMember(allData).then((resp) => {
            this.userId = resp.data.data.id;
            this.ifLoader = false;
            this.$emit('update:showModalAttendance', true);
            this.showModalGetStarted = false;
            pricingApi.updateMember({ tags: ['Lead'] }, this.userId).then((resp) => {
              console.log(resp);
            }).catch((err) => {
              console.log(err);
            });
          }).catch((err) => {
            this.ifLoader = false;
            console.log(err);
          });
          // this.$emit('update:showModalAttendance', true)
          // this.showModalGetStarted = false
        }
      });
    },
    async submitData() {
      this.submitCaptcha();
      // const { location: { hostname } } = window;
      // if (hostname === 'localhost') {
      //   this.submitCaptcha();
      // } else {
      //   this.$refs.recaptcha.execute();
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../assets/sass/utils/variables';

  .modals-get-started {
    position: fixed;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, .8);
    z-index: 9999;
    opacity: 1;
    height: 100%;
    /*width: 100vw;*/
    /*align-items: center;*/
    /*transition: opacity .5s ease-in;*/
    &-bg {
      margin: auto;
    }
    &__content {
      /*max-width: 955px;*/
      /*width: 100%;*/
      width: 955px;
      background: $color-white;
      border-radius: 10px;
      position: relative;
      padding: 65px 15px 110px 15px;
    }

    &__close {
      position: absolute;
      top: 23px;
      right: 16px;
      font-size: 16px;
      color: $color-jumbo;
      transition: transform .15s ease-in;
      cursor: pointer;

      &:hover {
        transform: rotate(90deg);
      }
    }

    &__title {
      font-family: $font-global-bold;
      line-height: 40px;
      font-size: 31px;
      color: $color-shark;
      text-align: center;
      margin-bottom: 16px;
    }

    &__desc {
      line-height: 34px;
      font-size: 24px;
      text-align: center;
      font-family: $font-global;
      margin-bottom: 40px;
    }

    &-form {
      max-width: 354px;
      width: 100%;
      margin: auto;

      &-radio {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }

      &__input {
        position: relative;
        height: 40px;
        background: $color-white;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        input {
          max-width: 354px;
          width: 100%;
          border: 1px solid $color-alto;
          border-radius: 4px;
          padding-left: 42px;
          padding-right: 15px;
          font-family: $font-global;
          font-size: 18px;
          color: $color-black;
          height: 40px;
          transition: border-color .15s ease-in;

          &:hover {
            border-color: $color-french-gray;
          }

          &:focus {
            border-color: $color-royal-blue-third;
          }
          &:focus::placeholder {
            opacity: 0;
          }

          &::-webkit-input-placeholder {
            color: $color-alto;
            font-family: $font-global;
          }

          &::-moz-placeholder {
            color: $color-alto;
            font-family: $font-global;
          }

          &:-moz-placeholder {
            color: $color-alto;
            font-family: $font-global;
          }

          &:-ms-input-placeholder {
            color: $color-alto;
            font-family: $font-global;
          }

          &::placeholder {
            color: $color-alto;
            font-family: $font-global;
          }
        }

        i {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 6px;
          color: $color-moody-blue;
          font-size: 22px;
        }

        .icon-msg-modal {
          font-size: 16px;
        }

        .icon-org-modal {
          font-size: 20px;
        }
      }

      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        margin-top: 30px;
        background: $color-moody-blue;
        height: 48px;
        border: none;
        font-family: $font-global-medium;
        font-size: 21px;
        color: $color-white;
        padding: 0 6px;
        width: 100%;
        max-width: 100%;
        border-radius: 5px;
        text-decoration: none;
        transition: opacity .2s ease-in, background .2s ease-in;
        user-select: none;
        -webkit-appearance: none;
        &_disabled {
          background: $color-gray;
          pointer-events: none;
        }

        &:hover {
          opacity: .8;
        }

        &:active {
          background: $color-martinique;
          opacity: 1;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          background: rgba(255, 255, 255, .5);
          opacity: 0;
          border-radius: 100%;
          transform: scale(1, 1) translate(-50%);
          transform-origin: 50% 50%;
        }

        @keyframes ripple {
          0% {
            transform: scale(0, 0);
            opacity: 1;
          }
          20% {
            transform: scale(25, 25);
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: scale(40, 40);
          }
        }

        &:focus:not(:active)::after {
          animation: ripple 1.5s ease-out;
        }
      }
    }

    &_blur {
      backdrop-filter: blur(8px);
      background: rgba(0, 0, 0, .9);
    }
  }

  .recaptcha-google-block {
    display: flex;
    justify-content: center;

    iframe {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  @keyframes scaleUp {
    0% {
      transform: translateY(1000px);
      opacity:0;
    }
    100% {
      transform: translateY(0);
      opacity:1;
    }
  }
  @keyframes scaleDown {
    0% {
      transform: translateY(0);
      opacity:1;
    }
    100% {
      transform: translateY(1000px);
      opacity:0;
    }
  }

  @media (max-width: 991px)  {
    .modals-get-started {
      &__content {
        max-width: 100%;
        width: calc(100vw - 45px);
        margin: 0 15px;
      }
    }
  }

  @media (max-width: 480px)  {
    .modals-get-started {

      &__close {
        top: 10px;
        right: 10px;
      }

      &__content {
        padding: 40px 15px 40px 15px;
      }

      &__title {
        font-size: 19px;
        line-height: 24px;
        margin-bottom: 5px;
      }

      &__desc {
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 20px;
      }

      &-form {
        &__btn {
          font-size: 14px;
          height: 32px;
          width: 200px;
        }

        &-radio {
          margin-bottom: 10px;
        }

        &__input {
          height: 32px;

          input {
            height: 32px;
            font-size: 16px;
          }

          i {
            font-size: 16px;
            top: 17px;
          }

          .icon-msg-modal {
            font-size: 12px;
          }

          .icon-org-modal {
            font-size: 14px;
          }
        }
      }
    }

    .radio-row__label {
      font-size: 14px;
    }

    .recaptcha-google-block {
      zoom: 0.8;
    }
  }

</style>

<style lang="scss" scoped>
  @import '../../assets/sass/utils/variables';
  .error {
    border-color: $color-punch !important;
    background-color: $color-white !important;
  }
  .help-modal {
    position: absolute;
    top: -15px;
    left: 0;
    font-size: 12px;
    color: $color-punch;
  }
  .if-loader {
    &:after {
      color: $color-moody-blue;
    }
  }
</style>
