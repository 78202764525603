import * as domains from '@/constants/domains';

const axios = require('axios');

export default {
  saveProfile(org) {
    console.log(org);
    const instWithCred = axios.create({
      baseURL: domains.PRICING_DOMEN,
    });
    return instWithCred.post('saveProfile', org);
  },
  getTerms(id, client) {
    console.log(id);
    const instWithCred = axios.create({
      baseURL: domains.PRICING_DOMEN,
    });
    return instWithCred.get(`terms?period=${id}&client=${client}`);
  },
  timezonesList(data) {
    const instWithCred = axios.create({
      baseURL: domains.PRICING_DOMEN,
    });
    const country = data ? `/${data}` : '';
    return instWithCred.get(`timezones${country}`);
  },
  checkDomain(domain) {
    const instWithCred = axios.create({
      baseURL: domains.PRICING_DOMEN,
    });
    console.log(domain);
    return instWithCred.get(`domain/${domain}`);
  },
  checkGeoInfo() {
    const instWithCred = axios.create({
      baseURL: domains.GEO_DOMEN,
    });
    return instWithCred.get('geo');
  },
  sendPay({ id, data }) {
    const instWithCred = axios.create({
      baseURL: domains.PRICING_DOMEN,
    });
    return instWithCred.post(`pay/${id}`, data);
  },
  sendPayEpic({ id }) {
    const instWithCred = axios.create({
      baseURL: domains.PRICING_DOMEN,
    });
    return instWithCred.get(`epicPayLink/${id}`);
  },
  createMember(data) {
    const instWithCred = axios.create({
      baseURL: domains.MEMBER_DOMAIN,
    });
    return instWithCred.post('new', { data });
  },
  updateMember(data, id) {
    const instWithCred = axios.create({
      baseURL: domains.MEMBER_DOMAIN,
    });
    return instWithCred.patch(`member/tags/${id}`, { data });
  },
  createAdmin(domain) {
    const instWithCred = axios.create({
      baseURL: domains.CHURCH_DOMEN,
    });
    return instWithCred.get(`${domain}/ready`);
  },
};
