<template>
  <label
    class="radio-row"
    @click="$emit('click')"
  >
    <span class="radio">
      <input
        v-if="!focused"
        class="radio__input"
        :checked="radioCheked"
        type="radio"
        :name="radioName"
        :value="radioValue"
        :tabindex="tabindex"
        @change="changeRadio"
      >
      <input
        v-if="focused"
        v-focus
        class="radio__input"
        :checked="radioCheked"
        type="radio"
        :name="radioName"
        :value="radioValue"
        :tabindex="tabindex"
        @change="changeRadio"
      >
      <span class="radio__icon">
      </span>
    </span>
    <span class="radio-row__label">
      {{ radioText }}
      <span
        v-if="radioTextSmall !== undefined && radioTextSmall.length > 0"
        class="radio-row__label-small"
      >
        ({{ radioTextSmall }})
      </span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'Radio',
  props: ['radioText', 'radioTextSmall', 'radioCheked', 'radioName', 'radioValue', 'focused', 'tabindex'],
  methods: {
    changeRadio() {
      this.$emit('changeRadio');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../assets/sass/utils/variables";
  .radio-checkbox-row{
    display: flex;
    flex-wrap: wrap;
    margin-top: -12px;
    margin-bottom: -12px;

    .radio-row, .checkbox-row{
      margin-right: 16px;
      margin-top: 12px;
      margin-bottom: 12px;

      &:last-child {
        margin-right: 0;
      }
    }

    &_small-margin {

      .radio-row, .checkbox-row {
        margin-right: 20px;
      }
    }

    &_no-wrap {
      flex-wrap: nowrap;
    }
  }
/*
  .radio-select-block {
    width: 100%;
  }
*/
  .radio-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    margin: 0 20px;

    &:hover {

      .radio__icon {
        border-color: $color-moody-blue;
      }
    }

    &_bottom-label {
      align-items: flex-start;

      .radio-row__label {
        display: block;
      }
    }

    &_inline-flex {
      display: inline-flex;
    }

    &__label {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: 8px;
      font: 16px/15px $font-global;
      color: $color-outer-space;
      user-select: none;

      &-small {
        width: 100%;
        min-width: 100%;
        font-size: 11px;
      }

      &_lg {
        font: 18px $font-global-medium;
      }
      &_static {
        position: static;
      }
    }
    &__label-bottom {
      display: block;
      margin-top: 8px;
      color: $color-gray-chateau;
      font: 13px/1.385 $font-global;
    }

    .radio {
      top: -1px;
    }
  }

  .radio {
    display: block;
    position: relative;
    width: 16px;
    min-width: 16px;
    height: 16px;

    &_hide {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
      z-index: 2;
      margin: 0;

      &:checked + .radio__icon {
        opacity: 1;
        border-color: $color-moody-blue;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $color-moody-blue;
        }
      }
    }

    &__icon {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      background: $color-white;
      border: 2px solid $color-gray-chateau;
      border-radius: 50%;
      transition: border-color .2s ease-in;

      &_hide {
        opacity: 0;
      }
    }
  }

</style>
