<template>
  <div
    id="g-recaptcha"
    class="g-recaptcha"
    :data-sitekey="sitekey"
    data-size="invisible"
  >
  </div>
</template>

<script>
export default {
  data() {
    return {
      // sitekey: '6LfXiqoUAAAAAOQ3kK5sJlaOR_2t5HqwHokquLCs',
      sitekey: '6LcKUsUhAAAAAFsZy6-tTOfkJLoi-WqjbMuN2jb1',
      // sitekey: '6Le5HxMUAAAAAPgb_he7E0Kj07s8hMhETPUSBwG9',
      widgetId: 0,
    };
  },
  mounted() {
    // render the recaptcha widget when the component is mounted
    this.$nextTick(() => {
      setTimeout(() => {
        this.render();
      }, 500);
    });
  },
  methods: {
    execute() {
      window.grecaptcha.execute(this.widgetId);
    },
    reset() {
      window.grecaptcha.reset(this.widgetId);
    },
    render() {
      console.log(window.grecaptcha);
      if (window.grecaptcha) {
        this.widgetId = window.grecaptcha.render('g-recaptcha', {
          sitekey: this.sitekey,
          // size: 'invisible',
          // the callback executed when the user solve the recaptcha
          callback: (response) => {
            try {
              if (response.length !== 0) {
                // emit an event called verify with the response as payload
                this.$emit('verify', response);
                // reset the recaptcha widget so you can execute it again
                this.reset();
              } else {
                console.warn('Error');
              }
            } catch (e) {
              console.warn(e);
            }
          },
        });
      }
    },
  },
};
</script>
